import * as Popper from '@popperjs/core';
import { Carousel, Dropdown, Popover, Toast, Modal } from 'bootstrap';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

global.$ = global.jQuery = require('jquery');
global.Modal = Modal;
global.Carousel = Carousel;
global.Swiper = Swiper;
global.SwiperNavigation = Navigation;
global.SwiperPagination = Pagination;
global.Popover = Popover;
global.Dropdown = Dropdown;
global.Toast = Toast;

$.fn.throttle = function(callback, delay) {
    let timerFlag = null;
    return (...args) => {
        if (timerFlag === null) {
            callback(...args);
            timerFlag = setTimeout(() => {
                timerFlag = null;
            }, delay);
        }
    };
}
