window.dataLayer = window.dataLayer || [];

/**
 * Ellenőrzi, hogy az átadott érték érvényes tömb index-e.
 *
 * @param {*} value Az ellenőrizendő érték.
 * @returns {boolean} `true`, ha az érték érvényes index, egyébként `false`.
 */
function isIndex(value) {
    return (
        /^(?:0|[1-9]\d*)$/.test(String(value))
        && Number(value) < Number.MAX_SAFE_INTEGER
    );
}

/**
 * Beállítja az értéket az objektum adott elérési útvonal szerinti tulajdonságára.
 * Ha az útvonal egy része nem létezik, akkor azt létrehozza.
 * Tömbök jönnek létre a hiányzó indextulajdonságokhoz,
 * míg objektumok az összes többi hiányzó tulajdonsághoz.
 *
 * @param {Object} object A módosítandó objektum.
 * @param {string|Array} path
 * A beállítandó tulajdonság elérési útvonala.
 * Vagy egy közvetlen tulajdonság kulcsa,
 * vagy az elérési út mentén lévő kulcsok tömbje.
 * @param {*} value A beállítandó érték.
 * @returns {Object} A módosított objektum.
 */
function setPropertyValue(object, path, value) {
    if (Object(object) !== object) {
        return object;
    }

    if (typeof path === 'string') {
        path = [path];
    }

    const { length } = path;
    const lastIndex = length - 1;

    for (
        let i = 0, nested = object, key, newValue;
        nested != null && i < length;
        nested = nested[key], ++i
    ) {
        key = String(path[i]);
        newValue = value;

        if (i < lastIndex) {
            const objValue = nested[key];
            newValue = (
                Object(objValue) === objValue
                ? objValue
                : (
                    isIndex(path[i + 1])
                    ? []
                    : {}
                )
            );
        }

        nested[key] = newValue;
    }

    return object;
}

/**
 * A Google Analytics felé történő adatbeküldést végzi.
 * Több argumentummal is meghívható, ekkor ezeket külön egységekként küldi be.
 *
 * @param {...Object} args A beküldendő adatobjektum(ok).
 */
export function ga4Push(...args) {
    window.dataLayer.push(...args);
    // eslint-disable-next-line max-len
    // INFO: config/googletagmanager.php -> is_debug hasznalja -> resources/views/components/frontend/gadebug.blade.php
    if (typeof window.dataLayerCallback === 'function') {
        window.dataLayerCallback(...args);
    }
}

/**
 * Ezzel a függvénnyel kezelhető az az eset,
 * amikor a Google Analytics felé beküldendő adatobjektum egy listát is tartalmaz,
 * amelyet korlátozott méretű darabokra osztva külön-külön kell beküldeni úgy,
 * hogy az adatobjektumban szereplő többi adat
 * a lista mindenegyes darabjával együtt be legyen küldve.
 * A függvény az adatok felosztásáról,
 * és a felosztott adatok beküldéséről is gondoskodik.
 *
 * @param {Object|Object[]} data A beküldendő adatobjektum(ok) a lista nélkül.
 * @param {string|Array} path
 * A lista adatobjektum(ok)on belüli helyének elérési útvonala.
 * Vagy egy közvetlen tulajdonság kulcsa,
 * vagy az elérési út mentén lévő kulcsok tömbje.
 * @param {Array} list A feldarabolandó lista.
 * @param {number} part
 * A lista darabjainak maximális elemszáma.
 * Ha értéke nem egy pozítív egész szám, akkor nincs darabokra osztás.
 * @param {number} [limit]
 * Az elküldhető listadarabok - vagy ha nincs darabolás,
 * akkor a listaelemek - maximális száma.
 * Ha értéke nem egy pozítív egész szám, akkor nincs korlát.
 */
export function ga4PartitionPush(
    data,
    path,
    list,
    part,
    limit,
) {
    if (!Array.isArray(data)) {
        data = [data];
    }

    if (Array.isArray(list) && part > 0 && part % 1 === 0) {
        const partition = [];
        const { length } = list;
        for (let i = 0, j = part; i < length; i = j, j += part) {
            partition.push(
                list.slice(i, j),
            );
        }

        list = (
            (limit > 0 && limit % 1 === 0)
            ? partition.slice(0, limit)
            : partition
        );

        data = data.reduce(
            (accumulator, item) => {
                list.forEach(
                    (segment) => {
                        accumulator.push(
                            setPropertyValue(
                                JSON.parse(
                                    JSON.stringify(item),
                                ),
                                path,
                                segment,
                            ),
                        );
                    },
                );
                return accumulator;
            },
            [],
        );
    }
    else {
        if (Array.isArray(list) && limit > 0 && limit % 1 === 0) {
            list = list.slice(0, limit);
        }

        data.forEach(
            (item) => {
                setPropertyValue(
                    item,
                    path,
                    list,
                );
            },
        );
    }

    ga4Push(...data);
}

/**
 * Előfordulhat olyan eset, hogy nincs clientId a méréshez.
 * Ilyenkor generálunk egy egyedi azonosítót.
 * @returns {string}
 */
export function generateRandomClientId() {
    const timeStamp = Math.round(+new Date() / 1000.0);
    let randomValue;
    try {
        const randomUi32Array = new Uint32Array(1);
        [randomValue] = window.crypto.getRandomValues(randomUi32Array);
    }
    catch (e) {
        // 32bit unsigned integert állít elő
        randomValue = Math.floor(Math.random() * (2 ** 32));
    }

    return [randomValue, timeStamp].join('.');
}
